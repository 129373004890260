import {Injectable} from '@angular/core';
import {UserRole} from '../authentication/user-role';
import {AuthenticationService} from '../authentication/authentication.service';

export class Dashboard {
  title: string;
  matIcon: string;
  /**
   * For internal dashboards you should also specify favicon image name. It will be updated along with tab name. Don't
   * forget to add favicon png image to assets/images/favicon.
   */
  faviconImageName?: string;
  allowedRoles?: string[];
  routerLink?: string;
  externalUrl?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardsService {

  static readonly BOOK_OF_RECORDS_DASHBOARD: Dashboard = {
    title: 'Book of Records',
    routerLink: 'book-of-records',
    matIcon: 'reorder',
    faviconImageName: 'reorder',
    allowedRoles: [UserRole.VISUAL7_INDEX]
  };
  static readonly LOCALIZATOR_DASHBOARD: Dashboard = {
    title: 'Localizator',
    routerLink: 'localizator',
    matIcon: 'public',
    faviconImageName: 'public',
    allowedRoles: UserRole.LOCALIZATOR_ALL_ROLES
  };
  static readonly APP_USER_STATE_TEMPLATES_DASHBOARD: Dashboard = {
    title: 'App User State Templates',
    routerLink: 'app-user-state-templates',
    matIcon: 'people',
    faviconImageName: 'people',
    allowedRoles: [UserRole.APP_USER_STATE_TEMPLATES]
  };
  static readonly STARLITE_SYMBOLICATOR_DASHBOARD: Dashboard = {
    title: 'Starlite Symbolicator',
    routerLink: 'starlite-symbolicator',
    matIcon: 'translate',
    faviconImageName: 'translate',
    allowedRoles: [UserRole.STARLITE_SYMBOLICATOR]
  };
  static readonly HOME_DASHBOARD: Dashboard = {
    title: 'Home',
    routerLink: '/',
    matIcon: 'home',
    // For some reason home.png doesn't work
    faviconImageName: 'visual7',
    allowedRoles: [UserRole.VISUAL7_INDEX]
  };
  static readonly REQUEST_BUILDER_DASHBOARD: Dashboard = {
    title: 'Request Builder',
    routerLink: 'request-builder',
    matIcon: 'border_all',
    faviconImageName: 'border_all',
    allowedRoles: [UserRole.GRID_BUILDER]
  };
  static readonly TOOLS_DASHBOARD: Dashboard = {
    title: 'Tools',
    routerLink: 'tools',
    matIcon: 'build',
    faviconImageName: 'build',
    allowedRoles: [UserRole.VISUAL7_INDEX]
  };
  static readonly CONFIG7_DASHBOARD: Dashboard = {
    title: 'Config7',
    routerLink: 'config7',
    matIcon: 'account_tree',
    faviconImageName: 'account_tree',
    allowedRoles: UserRole.CONFIG7_ALL_ROLES
  };
  static readonly CROSSPROMO_KPIS_DASHBOARD: Dashboard = {
    title: 'Crosspromo',
    routerLink: 'crosspromo',
    matIcon: 'trending_up',
    faviconImageName: 'trending',
    allowedRoles: [UserRole.NEWS]
  };
  static readonly EVENTS_HORIZON_DASHBOARD: Dashboard = {
    title: 'Events Horizon',
    routerLink: 'events-horizon',
    matIcon: 'event',
    faviconImageName: 'event',
    allowedRoles: UserRole.EVENTS_AS_CODE_ALL_ROLES
  };
  static readonly MEDIATION_DASHBOARD: Dashboard = {
    title: 'Mediation',
    routerLink: 'mediation',
    matIcon: 'dashboard',
    faviconImageName: 'dashboard',
    allowedRoles: [UserRole.MEDIATION_ADMIN, UserRole.MEDIATION_VIEWER, UserRole.MEDIATION_ADOPS]
  };
  static readonly MEDIATION_EXT_DASHBOARD: Dashboard = {
    title: 'Mediation external',
    routerLink: 'mediation-external',
    matIcon: 'schedule',
    faviconImageName: 'schedule',
    allowedRoles: [UserRole.MEDIATION_EXTERNAL, ]
  };


  constructor(private authenticationService: AuthenticationService) {
  }

  getDashboards(): Dashboard[] {
    const authService: AuthenticationService = this.authenticationService;
    return dashboards.filter((dashboard) => authService.hasAnyRole(dashboard.allowedRoles));
  }
}

const dashboards: Dashboard[] = [
  DashboardsService.HOME_DASHBOARD,
  {
    title: 'O7 Devices',
    externalUrl: 'https://visual7-legacy.outfit7.net/dashboard/o7devices/',
    matIcon: 'devices',
    allowedRoles: [
      UserRole.O7DEVICES_INTERNAL,
      UserRole.O7DEVICES_EXTERNAL,
      UserRole.O7DEVICES_ADMIN,
      UserRole.O7DEVICES_TESTER
    ]
  },
  DashboardsService.REQUEST_BUILDER_DASHBOARD,
  {
    title: 'Gdoc Updater',
    externalUrl: 'https://visual7-legacy.outfit7.net/dashboard/gdoc/',
    matIcon: 'insert_drive_file',
    allowedRoles: [UserRole.GDOC_VIEWER, UserRole.GDOC_EDITOR]
  },
  DashboardsService.CONFIG7_DASHBOARD,
  {
    title: 'AB tests',
    externalUrl: 'https://ab-tests.visual7.app/',
    matIcon: 'tune',
    allowedRoles: [UserRole.AB_TESTS_VIEWER, UserRole.AB_TESTS_EDITOR, UserRole.AB_TESTS_LIMITED_EDITOR]
  },
  DashboardsService.BOOK_OF_RECORDS_DASHBOARD,
  DashboardsService.LOCALIZATOR_DASHBOARD,
  DashboardsService.APP_USER_STATE_TEMPLATES_DASHBOARD,
  DashboardsService.EVENTS_HORIZON_DASHBOARD,
  DashboardsService.TOOLS_DASHBOARD,
  DashboardsService.STARLITE_SYMBOLICATOR_DASHBOARD,
  {
    title: 'Gladiators rankings',
    externalUrl: 'https://mythic-legends-rankings.visual7.app/',
    matIcon: 'leaderboard',
    allowedRoles: [UserRole.GLADIATORS_RANKINGS]
  },
  DashboardsService.CROSSPROMO_KPIS_DASHBOARD,
  {
    title: 'Coupons',
    externalUrl: 'https://coupons.visual7.app/',
    matIcon: 'card_giftcard',
    allowedRoles: [UserRole.COUPONS]
  },
  {
    title: 'Placements',
    externalUrl: 'https://placements.visual7.app/',
    matIcon: 'device_hub',
    allowedRoles: [UserRole.MEDIATION_ADMIN]
  },
  DashboardsService.MEDIATION_DASHBOARD,
  DashboardsService.MEDIATION_EXT_DASHBOARD,
  {
    title: 'News',
    externalUrl: 'https://visual7-legacy.outfit7.net/dashboard/news/',
    matIcon: 'list_alt',
    allowedRoles: [UserRole.NEWS]
  },
  {
    title: 'Legacy Adjuster',
    externalUrl: 'https://visual7-legacy.outfit7.net/dashboard/adjuster/',
    matIcon: 'import_export',
    allowedRoles: [UserRole.ADJUSTER]
  },
  {
    title: 'Campaigns',
    externalUrl: 'https://visual7-legacy.outfit7.net/dashboard/promo/',
    matIcon: 'live_tv',
    allowedRoles: [UserRole.AD_CAMPAIGNS]
  },
  {
    title: 'User Support',
    externalUrl: 'https://visual7-legacy.outfit7.net/dashboard/user-support/',
    matIcon: 'headset_mic',
    allowedRoles: [UserRole.USER_SUPPORT]
  },
  {
    title: 'Punisher',
    externalUrl: 'https://visual7-legacy.outfit7.net/dashboard/fake-apps/',
    matIcon: 'copyright',
    allowedRoles: [UserRole.PUNISHER]
  },
  {
    title: 'Manage account',
    externalUrl: 'https://accounts-be.outfit7.net/auth/realms/backend/account',
    matIcon: 'person',
  },
  {
    title: 'Build sizes',
    externalUrl: 'https://datastudio.google.com/s/tWwBFA6dWZk',
    matIcon: 'square_foot'
  },
  {
    title: 'In-app support',
    externalUrl: 'https://user-support.visual7.app/',
    matIcon: 'help'
  },
  {
    title: 'AppRocket',
    externalUrl: 'https://app-rocket.visual7.app/',
    matIcon: 'rocket_launch',
    allowedRoles: [UserRole.APP_ROCKET_VIEWER, UserRole.APP_ROCKET_EDITOR]
  },
  {
    title: 'Config7 V2',
    externalUrl: 'https://config7.visual7.app',
    matIcon: 'account_tree',
    allowedRoles: [UserRole.CONFIG7_VIEWER, UserRole.CONFIG7_EDITOR]
  },
];
